<template>
  <div class="about">
    <div class="row justify-content-start">
      <div class="col-12 bg">
        <div style="margin-top: 100px">
          <p class="title">隐形保镖电子质保查询系统</p>
        </div>
        <div class="row justify-content-start" style="margin: 100px 0px 0px 0px">
          <div class="col-4"></div>
          <div class="col-4">
            <p class="describe">输入完整的手机号或质保卡号进行质保查询</p>
            <input type="text" class="form-control" style="height: 45px" v-model="condition" />
          </div>
          <div class="col-4"></div>
        </div>
        <button class="button" style="margin: 60px 0px 0px 0px; width: 200px" @click="getQuality">
          提交查询
        </button>
      </div>
    </div>
    <div class="row" style="padding: 30px 0px 30px 0px; background-color: #f3f3f2">
      <div class="col-2"></div>
      <div class="col-8 price">
        <header class="p-header">
          <h3 style="font-size: 30px; color: #ffffff; padding-top: 20px">
            隐形保镖隐形车衣电子质保
          </h3>
          <p style="font-size: 20px; color: #ffffff; padding-top: 5px">
            DIGITAL CERTIFICATE
          </p>
        </header>
        <table class="table table-bordered table-hover" style="width: 95%; margin: 60px 30px 30px 30px"
          v-if="queryFlag">
          <tbody>
            <tr>
              <td>姓名</td>
              <td>{{ qualityInfo.name }}</td>
            </tr>
            <tr>
              <td>手机号</td>
              <td>{{ qualityInfo.phone }}</td>
            </tr>
            <tr>
              <td>汽车品牌</td>
              <td>{{ qualityInfo.carBrand }}</td>
            </tr>
            <tr>
              <td>汽车型号</td>
              <td>{{ qualityInfo.carModel }}</td>
            </tr>
            <tr>
              <td>汽车颜色</td>
              <td>{{ qualityInfo.carColor }}</td>
            </tr>
            <tr>
              <td>车牌号</td>
              <td>{{ qualityInfo.carNumber }}</td>
            </tr>
            <tr>
              <td>车架号</td>
              <td>{{ qualityInfo.vin}}</td>
            </tr>
            <tr>
              <td>施工单位</td>
              <td>{{ qualityInfo.workCompany }}</td>
            </tr>
            <tr>
              <td>施工技师</td>
              <td>{{ qualityInfo.workStaff }}</td>
            </tr>
            <tr>
              <td>施工时间</td>
              <td>{{qualityInfo.workDate}}</td>
            </tr>
            <tr>
              <td>施工部位</td>
              <td>{{qualityInfo.workPart}}</td>
            </tr>
            <tr>
              <td>产品类型</td>
              <td>{{ qualityInfo.productType}}</td>
            </tr>
            <tr>
              <td>产品级别</td>
              <td>{{ qualityInfo.productLevelName}}</td>
            </tr>
            <tr>
              <td>卷心号</td>
              <td>{{qualityInfo.rollNumber}}</td>
            </tr>
            <tr>
              <td>盒头号</td>
              <td>{{qualityInfo.boxNumber}}</td>
            </tr>
            <tr>
              <td>质保时间</td>
              <td>{{qualityInfo.validityDate}}{{" 质保"+qualityInfo.guaranteePeriod + '年'}}</td>
            </tr>
          </tbody>
        </table>
        <div class="row justify-content-start">
          <div class="col-2"></div>
          <div class="col-10">
            <p style="font-size: 14px; padding-right: 20px">
              为了保障您的消费权益，享受隐形保镖车衣厂家的质保，您的漆面保护膜必须从隐形保镖官方渠道购买，并经隐形保镖车衣合作门店施工，且在正常环境下使用。自装贴日起的质保年限内，厂商对仅因制造或者材料缺陷造成的开裂、起泡提供质保服务。此外，此质量保证仅在产品由隐形保镖车衣授权贴膜技师按照生产厂商提供的标准施工流程安装于汽车原厂漆表面，并使用推荐的保养方法维护的情况下适用。
            </p>
          </div>
        </div>
      </div>
      <div class="col-2"></div>
    </div>
  </div>
</template>
<script>

  export default {
    components: {},
    data () {
      return {
        condition: null,
        qualityInfo: {},
        queryFlag: false,
      };
    },
    computed: {
      qualityInfoList () {
        return this.$store.state.quality.qualityInfoList;
      },
    },
    created () { },
    methods: {
      getQuality () {
        if (this.condition.length !== 11) {
          return;
        }
        let params = {
          productNo: 'YXBB02',
          queryKey: this.condition
        }
        this.$store.dispatch('quality/qualityInfoList', params).then((res) => {
          console.log(res)
          if (res.length == 0) {
            this.queryFlag = true
            this.qualityInfo = null;
            alert("未查询到结果");
          } else {
            this.queryFlag = true
            this.qualityInfo = this.$store.state.quality.qualityInfoList[0]
            console.log(this.qualityInfo)
          }
        }).catch((err) => {
          console.log(err)
        })
      },
    },
  };
</script>
<style scoped>
  .bg {
    background-image: url("https://car-1301197907.cos.ap-beijing.myqcloud.com/price-1.jpg");
    height: 500px;
  }

  .title {
    font-size: 30px;
    color: #ffffff;
  }

  .describe {
    font-size: 14px;
    color: #ffffff;
  }

  .button {
    background-color: #ecbd4d;
    border: 1px solid #ecbd4d;
    border-radius: 4px;
    padding: 10px 15px 10px 15px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    color: #ffffff;
    font-size: 14px;
    font-weight: bold;
  }

  .button:hover {
    background-color: #ecbd4d;
  }

  .button:active {
    background-color: #ecbd4d;
  }

  .price {
    background-color: #ffffff;
    padding: 0;
  }

  .p-header {
    border-radius: 5px;
    background-color: #ecbd4d;
  }

  .p-header .title {
    color: #ffffff;
    padding: 30px 0 0 0;
  }
</style>